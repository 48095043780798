.cbt-screen-main {
  display: flex;
  justify-content: center;
  margin-left: 25vh;
  margin-right: 25vh;
  height: 90vh;
  align-items: center;
}

.heading-text {
  display: flex;
  align-self: center;
  margin-left: 25vh;
  margin-top: 5vh;
}

.custom-input-width {
  width: 80vh;
}

.sidebar-container {
  background-color: #042855;
  color: white;
  width: 15%;
  height: 90vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar {
  /* padding: 10px; */
  overflow-y: auto;
  width: 100%;
}

/* .content-container {
   margin-left: 15%; 
} */

.scrollable-content {
  max-height: 90vh;
  overflow-y: auto;
}

.question-form {
  padding: 20px;
}

.container {
  margin-left: 0;
}

.nav-link {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  font-size: 14px;
  text-align: center;
  margin-top: 2px;
  margin-bottom: 2px;
  color: white;
  border: 0.4px solid white;
  border-radius: 10px;
  cursor: pointer;
}
.nav-link:hover {
  background-color: white;
  color: #042855;
}

.selected {
  background-color: white;
  color: #042855;
}

.nav-item {
  width: 100%;
  text-align: center;
}

.sizable-box {
  width: 40vh;
  height: 25vh;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.selected-image {
  max-width: 100%;
  max-height: 100%;
}

.top-bar {
  background-color: #042855;
  color: white;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-bottom: 1px solid white;
}

.top-bar .test-title {
  text-align: center;
}

.top-bar .sub-btn {
  margin-left: auto;
}

.sub-btn {
  justify-self: right;
  color: white;
  margin-right: 10px;
  border: 0.5px solid white;
  border-radius: 7px;
}

.sub-btn:hover {
  background: white;
  color: #042855;
}

.custom-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.message {
  font-size: 18px;
  margin-bottom: 10px;
}

.close-btn {
  background: #042855;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #042855;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
